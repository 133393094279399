<template>
    <div class="photo-gallery">
        <div :class="(!fluid) ? 'container' : 'container-fluid'">

            <div class="row">
                <div class="col-12 text-center mb-4">
                    <span class="si--title">
                        {{ title }} 
                    </span>
                </div>
            </div>

            <!-- Buttons navigation -->
            <div class="row mb-0 mb-md-4" 
                style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;">

                <div class="col-6 col-md-1 order-1 order-md-1 flex-center p-1">
                    <button @click="selectBackElement()"
                        class="btn btn-sm btn-outline-danger hover-image p-0 w-100">
                            <img :src="arrow_back" width="36" height="36">    
                    </button>
                </div>
                <div class="col-12 col-md-10 order-3 order-md-2 p-1"
                    style="overflow: auto; white-space: nowrap;">
                        <button @click="selected_gallery = btn.title"
                            v-for="(btn, i_btn) in btns" :key="i_btn" 
                            class="btn btn-sm mx-1"
                            :class="(selected_gallery == btn.title) ? `btn-danger` : 'btn-secondary'">
                                {{ btn.title }}
                        </button>
                </div>
                <div class="col-6 col-md-1 order-2 order-md-3 flex-center p-1">
                    <button @click="selectNextElement()"
                        class="btn btn-sm btn-outline-danger hover-image p-0 w-100">
                            <img :src="arrow_next" width="36" height="36">
                    </button>
                </div>
            </div>

            <div class="row photos">
                <div class="col-sm-6 col-md-4 col-lg-3 item" 
                    v-for="(item, item_index) in images" :key="`gallery_img_${item_index}`">

                    <div class="square">
                        <img :src="item.img"
                             class="img img-responsive full-width" />

                        <img :src="square" class="img">
                        <img :src="zoom" class="image-zoom" @click="selectImageZoom(item.img)">
                    </div>

                </div>
            </div>

            <div class="row">
                <div class="col-12 mb-4">
                    <p v-html="description"
                        class="si--description-sm">
                    </p>
                </div>
            </div>
        </div>

        <div @click.stop="closeModal('overlay')"
            class="photo-modal" 
            v-show="show_modal">

            <div class="container">
                <div @click.stop="closeModal('content')" 
                    class="row">
                    <img :src="img_zoom" class="img-fluid cursor-pointer">
                    <div id="img_zoom" 
                        :style="`background: url('${img_zoom}')`">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import arrow_back from 'ASSETS/images/icon/arrow_back'
    import arrow_next from 'ASSETS/images/icon/arrow_next'
    import zoom from 'ASSETS/images/icon/zoom'
    import square from 'ASSETS/images/icon/square'
    import xmark from 'ASSETS/images/icon/xmark'

    export default {
        data() {
            return {
                zoom,
                square,
                xmark,
                show_modal: false,
                img_zoom: 'https://epicbootstrap.com/freebies/snippets/lightbox-gallery/assets/img/building.jpg',
                selected_gallery: null,
                arrow_back,
                arrow_next
            }
        },
        computed: {
            btns() {
                //console.log(this.gallery_images, 'gallery_images')
                return this.gallery_images.map(({ title, images }) => ({ title }))
            },
            images() {
                for(i in this.gallery_images) {
                    let key = this.gallery_images[i]

                    if(key.title === this.selected_gallery) {
                        return key.images
                    }
                }
            }
        },
        props: {
            gallery_images: {
                type: Array,
                required: false,
                default: []
            },
            title: {
                type: String,
                required: false,
                default: ''
            },
            description: {
                type: String,
                required: false,
                default: ''
            },
            fluid: {
                type: Boolean,
                required: false,
                default: false  
            },
            overlay_close: {
                type: Boolean,
                require: false,
                default: true
            },
        },
        methods: {
            closeModal(type) {
                console.log(`close type: ${type}`)

                if(type === 'content') {
                    return
                }
                if(type === 'overlay' && !this.overlay_close) {
                    return
                }
                this.show_modal = false
            },
            selectImageZoom(img) {
                // Set image
                this.img_zoom = img
                let dimensions = new Image()
                dimensions.src = this.img_zoom

                // Dimension screen
                let device_width = document.documentElement.clientWidth
                let device_height = document.documentElement.clientHeight

                let This = this
                setTimeout(function() {
                    // console.log(dimensions)
                    // console.log(dimensions.width)
                    // console.log(dimensions.height)
                    let dim_width = dimensions.width
                    let dim_height = dimensions.height

                    const relationHxW = dim_height / dim_width
                    const height_image = device_width * relationHxW

                    document.getElementById("img_zoom").style.height = `${dim_height}px`;
                    document.getElementById("img_zoom").style.width = `${dim_width}px`;
                    // let DIV_img_zoom = document.getElementById('img_zoom')
                    // DIV_img_zoom.style.width = dimensions.width
                    // DIV_img_zoom.style.height = dimensions.height

                    This.show_modal = true
                }, 500);
            },
            selectBackElement() {
                for(let i in this.btns) {
                    if(this.btns[i].title == this.selected_gallery) {
                        let next_item = (i > 0) ? Number(i) - 1 : this.btns.length - 1
                        this.selected_gallery = this.btns[next_item].title
                        break;
                    }
                }
            },
            selectNextElement() {
                for(let i in this.btns) {
                    if(this.btns[i].title == this.selected_gallery) {
                        let next_item = (i < this.btns.length - 1) ? Number(i) + 1 : 0
                        this.selected_gallery = this.btns[next_item].title
                        break;
                    }
                }
            }
        },
        created() {

            this.selected_gallery = this.gallery_images[0].title


            var addZoom = target => {
                //console.log(target, 'target')
                // (A) GET CONTAINER + IMAGE SOURCE
                let container = document.getElementById(target),
                    imgsrc = container.currentStyle || window.getComputedStyle(container, false);
                    imgsrc = imgsrc.backgroundImage.slice(4, -1).replace(/"/g, "");

                // (B) LOAD IMAGE + ATTACH ZOOM
                let img = new Image();
                img.src = imgsrc;
                img.onload = () => {
                    // (B1) CALCULATE ZOOM RATIO
                    let ratio = img.naturalHeight / img.naturalWidth,
                        percentage = ratio * 100 + "%";

                    // (B2) ATTACH ZOOM ON MOUSE MOVE
                    container.onmousemove = e => {
                    let rect = e.target.getBoundingClientRect(),
                        xPos = e.clientX - rect.left,
                        yPos = e.clientY - rect.top,
                        xPercent = xPos / (container.clientWidth / 100) + "%",
                        yPercent = yPos / ((container.clientWidth * ratio) / 100) + "%";

                    Object.assign(container.style, {
                        backgroundPosition: xPercent + " " + yPercent,
                        backgroundSize: img.naturalWidth + "px"
                    });
                };

                // (B3) RESET ZOOM ON MOUSE LEAVE
                container.onmouseleave = e => {
                    Object.assign(container.style, {
                        backgroundPosition: "center",
                        backgroundSize: "cover"
                    });
                };
              }
            };

            // (C) ATTACH FOLLOW ZOOM
            window.onload = () => addZoom("img_zoom");
        }
    }
</script>

<style lang="scss" scoped>
    .photo-gallery {
        color: #313437;

        & p {
            color:#7d8285;
        }

        & .photos {
            max-height: 700px;
            overflow-y: scroll;
            // border: 1px solid red;
            // margin-bottom: 20px;
            padding-bottom: 44px;
        }

        & .item {
            padding: 10px;
            //max-height: 234.75px;

            .square {
                position: relative;
                margin: 4px;

                .img {
                    // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                    border-radius: 20px;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: 50% 50%;

                    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
                }

                .image-zoom {
                    position: absolute;
                    bottom: 10px;
                    right: 10px;
                    cursor: pointer;
                }
            }

            .square::after {
                padding-bottom: 100%;
                display: block;
                content: "";
            }
        }

        .photo-modal {
            z-index: 199999;
            background: rgba(0,0,0, 0.7);
            position: fixed;
            bottom: 0;
            right: 0;
            left: 0;
            top: 0;
            cursor: w-resize;

            .container {
                position: fixed;
                display: flex;
                align-items: center;
                justify-content: center;
                bottom: 0;
                right: 0;
                left: 0;
                top: 0;
            }
        }

        #img_zoom {
            /* (A) DIMENSIONS */
            width: 0px;
            height: 0px;
            max-width: 90vw;
            display: none;

            /* (B) BACKGROUND IMAGE */
            background-position: center;
            background-size: cover;
            cursor: zoom-in;
        }
    }
</style>